<template>
  <div>
    <a-form layout="inline">
      <a-form-item
          label="路由path"
      >
        <a-input v-model="query.path" placeholder="请输入路由path" @pressEnter="customQuery"/>
      </a-form-item>

      <a-form-item
          label="路由name"
      >
        <a-input v-model="query.name" placeholder="请输入路由name" @pressEnter="customQuery"/>
      </a-form-item>

      <a-form-item
          label="路由名称"
      >
        <a-input v-model="query.routeName" placeholder="请输入路由名称" @pressEnter="customQuery"/>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="customQuery">查询</a-button>
        <a-button style="margin-left: 10px" type="primary" @click="editRoute(undefined)">增加</a-button>
      </a-form-item>
    </a-form>

    <a-table bordered :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"  size="middle"
             style="margin-top: 24px;"
             :scroll="{ y: 600}"
             @change="handleTableChange" @expand="handleExpand">
      <span slot="isPage" slot-scope="text, record">
        {{ text ? '是' : '否' }}
      </span>

      <span slot="levelCode" slot-scope="text, record">
        {{ levelCodeList[text - 1] && levelCodeList[text - 1].label }}
      </span>
      <span slot="allowDerived" slot-scope="text, record">
        <a-popconfirm
            cancel-text="取消"
            :ok-text="record.allowDerived ? '取消派生' : '允许派生'"
            title="确认操作？"
            @confirm="updateDerived(record)"
        >
            <a >{{record.allowDerived ? '是' : '否'}}</a>
          </a-popconfirm>
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="editRoute(record)">编辑</a>
        <a-divider type="vertical"/>
        <a @click="editRoute({...emptyRoute, parentCode: record.routeCode})">添加子路由</a>
        <a-divider type="vertical"/>
        <a-popconfirm
            cancel-text="取消"
            ok-text="删除"
            title="确认删除？"
            @confirm="deleteRoute(record)"
        >
          <a style="color: red;">删除</a>
        </a-popconfirm>
      </span>

      <span slot="permissionAction" slot-scope="text, record">
        <a @click="showPermissionList(record)">权限列表</a>
      </span>
    </a-table>

    <edit ref="edit" @success="queryRouteList"/>
    <permission-list ref="permissionList"/>
  </div>
</template>

<script>
import edit from './component/routeEdit'
import permissionList from "./component/routePermissionList";
import _ from "lodash";
import {deleteRoute, deleteUserCache, queryChildRoute, queryRouteList, updateRouteDerivedApi} from "@/api/route";

export default {
  name: "routeList",
  components: {edit, permissionList},
  data() {
    return {
      query: {
        path: undefined,
        name: undefined,
        routeName: undefined,
        parentCode: undefined,
      },
      columns: [
        {
          title: '路由编号',
          dataIndex: 'routeCode',
          key: 'routeCode',
          ellipsis: true
        },
        {
          title: '路由path',
          dataIndex: 'path',
          key: 'path',
        },
        {
          title: '路由name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '路由名称',
          dataIndex: 'routeName',
          key: 'routeName',
          ellipsis: true
        },
        {
          title: '等级',
          dataIndex: 'level',
          key: 'level',
        },
        {
          title: '模块深度',
          dataIndex: 'levelCode',
          key: 'levelCode',
          scopedSlots: {customRender: 'levelCode'},
        },
        {
          title: '描述',
          dataIndex: 'represent',
          key: 'represent',
          ellipsis: true

        },
        {
          title: '上级路由',
          dataIndex: 'parentCode',
          key: 'parentCode',
          ellipsis: true

        },
        {
          title: '权限操作',
          key: 'permissionAction',
          scopedSlots: {customRender: 'permissionAction'},
        },
        {
          title: '是否允许派生',
          key: 'allowDerived',
          scopedSlots: {customRender: 'allowDerived'},
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          scopedSlots: {customRender: 'action'},
        }
      ],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: true,
      emptyRoute: {
        id: undefined,
        routeCode: undefined,
        name: undefined,
        path: undefined,
        represent: undefined,
        routeName: undefined,
        parentCode: undefined,
        isRoute: undefined
      },
      levelCodeList: [
        {
          levelCode: 1,
          label: '一级路由',
        },
        {
          levelCode: 2,
          label: '二级路由',
        },
        {
          levelCode: 3,
          label: '三级路由',
        },
        {
          levelCode: 4,
          label: '四级路由',
        },
      ],
    }
  },
  mounted() {
    this.queryRouteList()
  },
  methods: {
    updateDerived(record){
      updateRouteDerivedApi({routeCode: record.routeCode, allowDerived: !record.allowDerived}).then(res => {
        this.$message.success('操作成功')
        this.queryRouteList()
      }).catch(() => {
        this.$message.error('操作失败')
      })
    },
    editRoute(route) {
      this.$refs.edit.toggleVisible(route)
    },
    async queryRouteList() {
      this.loading = true

      let data = await queryRouteList(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryRouteList()
    },
    async customQuery() {
      this.pagination.current = 1
      await this.queryRouteList()
    },
    async deleteRoute(route) {
      await deleteRoute([route.id])

      this.$message.success('删除成功')
      await this.queryRouteList()
      await deleteUserCache()
    },
    convertQuery() {
      return {
        ...this.query,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (route, index) => {
        return {
          key: route.routeCode,
          ...route,
          children: []
        }
      })
    },
    async handleExpand(expanded, record) {
      if (expanded) {
        record.children = _.map((await queryChildRoute({parentCode: record.routeCode})).result, value => {
          return {
            key: value.routeCode,
            ...value,
            children: []
          }
        })
      }
    },
    showPermissionList(record) {
      this.$refs.permissionList.toggleVisible(record.routeCode)
    }
  },
}
</script>

<style scoped>

</style>
