<template>
  <a-modal
      v-if="visible"
      :title="title"
      :visible="visible"
      @cancel="handleCancel"
      @ok="handleOk"
  >
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" type="inline">
      <a-form-item label="权限编号">
        <a-input-search
            v-decorator="['permissionCode', { rules: [{ required: true, message: '请输入权限编号' }], initialValue: item && item.permissionCode }]"
            readonly @search="showPermissionSelect">
          <a-button slot="enterButton" type="primary">
            选择权限
          </a-button>
        </a-input-search>
        <span v-if="permission">
          权限名称：{{ permission.permissionName }}
          <br/>
          权限：{{ permission.permission }}
        </span>
      </a-form-item>

      <a-form-item label="前端参数">
        <a-input
            v-decorator="['fontValue', { rules: [], initialValue: item && item.fontValue }]"
        />
      </a-form-item>
    </a-form>

    <permission-select ref="permissionSelect" @success="selectPermission"/>
  </a-modal>
</template>

<script>

import {deleteUserCache, updateRoutePermission} from "@/api/route";
import permissionSelect from "./routePermissionSelect";

export default {
  name: "permissionEdit",
  components: {permissionSelect},
  mounted() {
  },
  data() {
    return {
      title: this.item ? '修改权限' : '新增权限',
      visible: false,
      form: this.$form.createForm(this),
      item: undefined,
      routeCode: undefined,
      permission: undefined
    }
  },
  methods: {
    toggleVisible(item, routeCode) {
      this.item = item
      this.routeCode = routeCode
      this.permission = undefined
      this.visible = !this.visible
    },
    handleOk(e) {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          await updateRoutePermission({
            ...this.item,
            ...values,
            isAdd: this.item == null,
            routeCode: this.routeCode,
          })
          await deleteUserCache()
          this.$message.success('成功')
          this.visible = false;
          this.$emit('success')
        }
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    showPermissionSelect() {
      this.$refs.permissionSelect.toggleVisible()
    },
    selectPermission(permission) {
      this.permission = permission
      this.form.setFieldsValue({
        permissionCode: permission.permissionCode
      })
    }
  },
  watch: {
    item: {
      handler: function (val, oldVal) {
        this.title = this.item ? '修改权限' : '新增权限'
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
