<template>
  <a-modal
      v-if="visible"
      :visible="visible"
      title="权限列表"
      width="60%"
      @cancel="handleCancel"
      @ok="handleOk"
  >
    <a-form layout="inline">
      <a-form-item>
        <a-button style="margin-left: 10px" type="primary" @click="editPermission(undefined)">增加</a-button>
      </a-form-item>
    </a-form>

    <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"
             style="margin-top: 24px;"
             @change="handleTableChange">
      <span slot="action" slot-scope="text, record">
        <a @click="editPermission(record)">编辑</a>
        <a-divider type="vertical"/>
        <a-popconfirm
            cancel-text="取消"
            ok-text="删除"
            title="确认删除？"
            @confirm="deletePermission(record)"
        >
          <a style="color: red;">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <permission-edit ref="permissionEdit" @success="customQuery"/>
  </a-modal>
</template>

<script>
import {
  deleteRoutePermission, deleteUserCache,
  queryRoutePermissionList,
} from "@/api/route";
import _ from "lodash";
import permissionEdit from "./routePermissionEdit";

export default {
  name: "permission",
  components: {permissionEdit},
  data() {
    return {
      query: {
        routeCode: undefined,
      },
      columns: [
        {
          title: '权限编号',
          dataIndex: 'permissionCode',
          key: 'permissionCode',
        },
        {
          title: '权限名称',
          dataIndex: 'permissionName',
          key: 'permissionName',
        },
        {
          title: '权限',
          dataIndex: 'permission',
          key: 'permission',
        },
        {
          title: '前端参数',
          dataIndex: 'fontValue',
          key: 'fontValue',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {customRender: 'action'},
        }
      ],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: true,
      visible: false,
    }
  },
  methods: {
    toggleVisible(routeCode) {
      this.query.routeCode = routeCode
      this.visible = !this.visible
      this.queryPermissionList()
    },
    handleOk(e) {
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryPermissionList()
    },
    async queryPermissionList() {
      this.loading = true

      let data = await queryRoutePermissionList(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    async customQuery() {
      this.pagination.current = 1
      await this.queryPermissionList()
    },
    async deletePermission(permission) {
      await deleteRoutePermission([permission.id])

      this.$message.success('删除成功')
      await this.queryPermissionList()
      await deleteUserCache()
    },
    convertQuery() {
      return {
        ...this.query,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertPagination(data) {
      this.pagination.current = data.number + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return _.map(data, (permission, index) => {
        return {
          key: permission.id,
          ...permission,
        }
      })
    },
    editPermission(permission) {
      this.$refs.permissionEdit.toggleVisible(permission, this.query.routeCode)
    }
  },
}
</script>

<style scoped>

</style>
