import service from './network';

export function queryModuleLevelList() {
    return service.request({
        url: '/module/query/moduleLevel',
        method: 'get',
    })
}

export function queryModuleList(data) {
    return service.request({
        url: '/module/query',
        method: 'post',
        data
    })
}

export function updateModule(data) {
    return service.request({
        url: '/module/update',
        method: 'put',
        data
    })
}

export function deleteModule(data) {
    return service.request({
        url: '/module/delete',
        method: 'delete',
        data
    })
}

export function queryTypeList() {
    return service.request({
        url: '/module/type/query/list',
        method: 'get',
    })
}

export function updateModuleDerivedApi(data) {
    return service.request({
        url: '/module/update/derived',
        method: 'put',
        data
    })
}
