<template>
  <a-modal
      v-if="visible"
      :title="title"
      :visible="visible"
      @cancel="handleCancel"
      @ok="handleOk"
  >
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" type="inline">
      <a-form-item label="路由path">
        <a-input
            v-decorator="['path', { rules: [{ required: true, message: '请输入路由path' }], initialValue: item && item.path }]"
        />
      </a-form-item>
      <a-form-item label="路由name">
        <a-input
            v-decorator="['name', { rules: [{ required: true, message: '请输入路由name' }], initialValue: item && item.name }]"
        />
      </a-form-item>
      <a-form-item label="路由名称">
        <a-input
            v-decorator="['routeName', { rules: [{ required: true, message: '请输入路由名称' }], initialValue: item && item.routeName }]"
        />
      </a-form-item>
      <a-form-item label="等级">
        <a-select
            v-decorator="['level', { rules: [{ required: true, message: '请选择等级' }], initialValue: item && item.level }]"
            style="width: 100%;">
          <a-select-option v-for="level in moduleLevelList" :key="level.levelCode" :value="level.levelCode">
            {{ level.levelCode }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="模块深度">
        <a-select
            v-decorator="['levelCode', { rules: [{ required: true, message: '请选择模块深度' }], initialValue: item && item.levelCode }]"
            style="width: 100%;">
          <a-select-option v-for="level in levelCodeList" :key="level.levelCode" :value="level.levelCode">
            {{ level.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="页面描述">
        <a-input
            v-decorator="['represent', { rules: [{ required: true, message: '请输入页面描述' }], initialValue: item && item.represent }]"
        />
      </a-form-item>
      <a-form-item label="上级路由编号">
        <a-input
            v-decorator="['parentCode', { rules: [], initialValue: item && item.parentCode }]"
        />
      </a-form-item>
      <!--      <a-form-item label="是否为页面">-->
      <!--        <a-radio-group-->
      <!--            v-decorator="['isPage', { rules: [{ required: true, message: '请选择是否为页面' }], initialValue: item && item.isPage || false }]">-->
      <!--          <a-radio-button :value="true">-->
      <!--            是-->
      <!--          </a-radio-button>-->
      <!--          <a-radio-button :value="false">-->
      <!--            否-->
      <!--          </a-radio-button>-->
      <!--        </a-radio-group>-->
      <!--      </a-form-item>-->
    </a-form>
  </a-modal>
</template>

<script>

import {deleteUserCache, updateRoute} from "@/api/route";
import {queryModuleLevelList} from "@/api/module";

export default {
  name: "routeEdit",
  mounted() {
    this.queryModuleLevelList()
  },
  data() {
    return {
      title: this.item ? '修改页面' : '新增页面',
      visible: false,
      form: this.$form.createForm(this),
      item: {},
      moduleLevelList: [],
      levelCodeList: [
        {
          levelCode: 1,
          label: '一级路由',
        },
        {
          levelCode: 2,
          label: '二级路由',
        },
        {
          levelCode: 3,
          label: '三级路由',
        },
        {
          levelCode: 4,
          label: '四级路由',
        },
      ],
    }
  },
  methods: {
    toggleVisible(item) {
      this.item = item
      this.visible = !this.visible
    },
    handleOk(e) {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          await updateRoute({
            ...this.item,
            ...values,
          })

          await deleteUserCache()
          this.$message.success('成功')
          this.visible = false;
          this.$emit('success')
        }
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    async queryModuleLevelList() {
      this.moduleLevelList = (await queryModuleLevelList()).result
    }
  },
  watch: {
    item: {
      handler: function (val, oldVal) {
        this.title = this.item ? '修改页面' : '新增页面'
      },
      deep: true
    },
  }
}
</script>

<style scoped>

</style>
